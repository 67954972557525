import React from "react"
import styled from "@emotion/styled";

const ButtonRoot = styled.a`
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    padding: 0.5rem 4rem;
    border: 2px solid #fff;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    transition: all .2s ease-in-out;

    @media (hover: hover) {
        :hover {
            transform: scale(1.03);
        }
    }
`;


const ButtonOutline = ({ children, href, onClick }) => {
    return (
        <ButtonRoot href={href} onClick={onClick}>
            {children}
        </ButtonRoot>
    )
}

export default ButtonOutline;
